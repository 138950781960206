.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  
}



.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;

}




.swiper-slide img {
  display: block;
  width: 330px;
  height: 300px;


}


.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  
}

.swiper-button-next{
  color: rgb(155, 31, 31);
}

.swiper-button-prev{
  color: rgb(155, 31, 31);
}

.swiper-pagination-bullet-active {
  background-color: rgb(155, 31, 31);
}